import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Bookmark from '../../../../components/bookmark';
import * as actions from '../../../../actions';

const FEATURE_NAME = 'bookmark';
const WISHLIST_SAVE_BUTTON = 'wishlist_save_button';

/** @TODO we should just pass the id and not rely on item_id,product_id */
const mapStateToProps = ({ components }, { showLabel, csrfToken }) => ({
  isBookmarked: components[FEATURE_NAME]?.is_bookmarked,
  showLabel,
  csrfToken,
  remove: components[FEATURE_NAME]?.delete,
  itemId: components[FEATURE_NAME]?.item_id,
  add: components[FEATURE_NAME]?.add,
  isFetching: components[FEATURE_NAME]?.isFetching,
  label: components[FEATURE_NAME]?.label,
  wishlist: {
    action: components[WISHLIST_SAVE_BUTTON]?.action,
    label: components[WISHLIST_SAVE_BUTTON]?.action?.label?.text,
    target: components[WISHLIST_SAVE_BUTTON]?.targetDynamic || components[WISHLIST_SAVE_BUTTON]?.action?.target,
    show: components[WISHLIST_SAVE_BUTTON]?.show,
    title: components[WISHLIST_SAVE_BUTTON]?.title,
    loading_text: components[WISHLIST_SAVE_BUTTON]?.loading_text,
    showOnlyForWishlistVariant: components[WISHLIST_SAVE_BUTTON]?.state === 'VISIBLE',
    gift_registry: components[WISHLIST_SAVE_BUTTON]?.gift_registry,
  },
});

const mapDispatchToProps = dispatch => ({
  onClick: e => {
    e.preventDefault();
    return dispatch(actions.toggleBookmark());
  },
  fetchBookmark: () => dispatch(actions.fetchBookmark()),
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  openWishlistModalBS: () => dispatch(actions.openWishlistModalBS()),
  closeWishlistModalBS: isBookmarked => dispatch(actions.closeWishlistModalBS(isBookmarked)),
  updateGiftRegistry: check => dispatch(actions.fetchUpdatedWishlists(check)),
  toggleGiftRegistryCheckbox: check => dispatch(actions.toggleGiftRegistryCheckbox(check)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Bookmark);
export { mapDispatchToProps, mapStateToProps };
