import React, { useState } from 'react';
import { arrayOf, bool, shape, string, number, func } from 'prop-types';
import { Table, TableBody, TableRow, TableData, TableHeader } from '@andes/table';
import classNames from 'classnames';
import { trackEvent } from '../../lib/tracking';
import SpecOther from './spec-other';
import SpecOtherCollapsable from './spec-other-collapsable';
import SpecGroupsCollapsable from './spec-groups-collapsable.pdp';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'ui-pdp-specs';
const STRIPED_TYPE = 'STRIPED';
const MAX_ROWS_SPLIT = 2;
const MAX_ROWS = 12;

const SpecRowGroups = ({ action, spec, isGroupCollapsed, restTechGroups, onClick }) => {
  if (!spec) {
    return null;
  }

  if (action) {
    return (
      <SpecGroupsCollapsable
        className={namespace}
        title={spec.title}
        attributes={spec.attributes}
        action={action}
        isCollapsed={isGroupCollapsed}
        restGroups={restTechGroups}
        onClick={onClick}
      />
    );
  }

  return (
    <div className={`${namespace}__rest`}>
      <SpecOther className={namespace} title={spec.title} attributes={spec.attributes} key={spec.title} />
      {restTechGroups.map(otherSpec => (
        <SpecOther
          className={`${namespace}-groups`}
          title={otherSpec.title}
          attributes={otherSpec.attributes}
          key={otherSpec.title}
        />
      ))}
    </div>
  );
};

SpecRowGroups.propTypes = {
  action: shape({ target: string, track: shape({}) }),
  spec: shape({
    title: string.isRequired,
    attributes: arrayOf(
      shape({
        id: string,
        text: string.isRequired,
      }),
    ),
  }),
  isGroupCollapsed: bool,
  restTechGroups: arrayOf(
    shape({
      title: string,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
  onClick: func,
};

SpecRowGroups.defaultProps = {
  restTechGroups: [],
  isGroupCollapsed: false,
  spec: null,
  action: null,
  onClick: null,
};

const SpecRowsGroupsByDefault = ({ action, specs, showSingleTechSpec, otherTechSpecs }) => {
  if (showSingleTechSpec || !otherTechSpecs || otherTechSpecs.length <= 0) {
    return null;
  }

  return otherTechSpecs.map(spec => {
    const actualRows = spec.attributes.length / MAX_ROWS_SPLIT;
    const shouldShowTitle = specs.length > 1;
    if (MAX_ROWS < actualRows && action) {
      return (
        <SpecOtherCollapsable
          className={namespace}
          title={spec.title}
          attributes={spec.attributes}
          action={action}
          key={spec.title}
          showTitle={shouldShowTitle}
        />
      );
    }

    return (
      <SpecOther
        className={namespace}
        title={spec.title}
        attributes={spec.attributes}
        key={spec.title}
        showTitle={shouldShowTitle}
      />
    );
  });
};

SpecRowsGroupsByDefault.propTypes = {
  showSingleTechSpec: bool,
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }).isRequired,
  otherTechSpecs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
  specs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
};

const SpecsPdp = ({ action, viewport_track, className, specs, showSingleSpec, showFirstTitle, max_groups }) => {
  const mainSpecs = specs ? specs.filter(spec => spec.type === STRIPED_TYPE) : [];
  const otherSpecs = specs ? specs.filter(spec => spec.type !== STRIPED_TYPE) : [];
  const [mainGroupSpec, ...restSpecs] = otherSpecs;
  const restGroups = restSpecs;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  const onClick = e => {
    e.preventDefault();
    if (action && action.track) {
      trackEvent(action.track);
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div ref={viewRef} className={classNames(namespace, className)}>
      {showFirstTitle && specs && specs.length > 0 && <h2 className={`${namespace}__title`}>{specs[0].title}</h2>}
      <div className={`${namespace}__tables`}>
        {mainSpecs.map(spec => (
          <div className={`${namespace}__table`} key={spec.id}>
            <Table>
              <TableBody>
                {spec.attributes.map(attribute => (
                  <TableRow selected={false} key={attribute.id}>
                    <TableHeader
                      className={classNames(`${namespace}__table__column`, `${namespace}__table__column-title`)}
                      scope="row"
                    >
                      {attribute.id}
                    </TableHeader>
                    <TableData className={`${namespace}__table__column`}>{attribute.text}</TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}
      </div>
      {max_groups ? (
        <SpecRowGroups
          action={action}
          spec={mainGroupSpec}
          isGroupCollapsed={isCollapsed}
          restTechGroups={restGroups}
          onClick={onClick}
        />
      ) : (
        <SpecRowsGroupsByDefault
          action={action}
          specs={specs}
          showSingleTechSpec={showSingleSpec}
          otherTechSpecs={otherSpecs}
        />
      )}
    </div>
  );
};

SpecsPdp.propTypes = {
  className: string,
  showFirstTitle: bool,
  showSingleSpec: bool,
  max_groups: number,
  action: shape({ target: string, track: shape({}) }),
  specs: arrayOf(
    shape({
      title: string.isRequired,
      attributes: arrayOf(
        shape({
          id: string,
          text: string.isRequired,
        }),
      ),
    }),
  ),
  viewport_track: shape({}),
};

SpecsPdp.defaultProps = {
  className: '',
  showFirstTitle: true,
  showSingleSpec: false,
  specs: [],
  action: null,
  max_groups: null,
  viewport_track: null,
};

export default SpecsPdp;
