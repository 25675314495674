import React from 'react';
import { func, node, string } from 'prop-types';
import { Form } from '@andes/form';
import classnames from 'classnames';

const namespace = 'ui-pdp-buybox';

const BuyBox = ({ children, className, onSubmit }) => (
  <Form className={classnames(namespace, className)} id="buybox-form" method="get" onSubmit={onSubmit}>
    {children}
  </Form>
);

BuyBox.propTypes = {
  children: node.isRequired,
  className: string,
  onSubmit: func,
};

BuyBox.defaultProps = {
  className: '',
  onSubmit: null,
};

export default BuyBox;
