import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import Actions from '../../../../../components/buybox/actions';
import * as acts from '../../../../../actions/index';

const FEATURE_NAME = 'main_actions';

const mapStateToProps = ({ components: { [FEATURE_NAME]: actions } }, { onSubmit, parentUrl }) => ({
  ...actions,
  form: {
    ...actions.form,
    parent_url: parentUrl,
  },
  onSubmit,
});

const mapDispatchToProps = dispatch => ({
  showAddToCartModal: ({ itemId, labelText }) => dispatch(acts.showAddToCartModal({ itemId, labelText })),
  showQuestionsAiModal: ({ target, disabled, type, called_from, source }) =>
    dispatch(acts.showQuestionsAiModal({ target, disabled, type, called_from, source })),
  showVariationsError: (deviceType, actionKey) => dispatch(acts.showVariationsError(deviceType, actionKey)),
  triggerBuyNowOnePayForAll: actionKey => dispatch(acts.triggerBuyNowOnePayForAll(actionKey)),
  triggerSequencer: buttonKey => dispatch(acts.triggerSequencer(buttonKey)),
  saveFrontendStatsd: data => dispatch(acts.saveFrontendStatsd(data)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Actions);

export { mapDispatchToProps };
