import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions';

const BuyBoxOffers = loadable(() => import('../../../../components/buy-box-offers'));

const FEATURE_NAME = 'buy_box_offers';

const mapStateToProps = ({ components: { [FEATURE_NAME]: buyBoxOffers } }, ownProps) => ({
  ...buyBoxOffers,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  onPaymentMethodSelected: (e, offer_type) => {
    e.preventDefault();
    return dispatch(actions.selectPaymentMethod(offer_type));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(BuyBoxOffers);
