import cloneDeep from 'lodash/cloneDeep';

const applyCouponSummaryToSelectedOffers = (buy_box_offers, newCouponSummary) => {
  const updatedItems = cloneDeep(buy_box_offers)?.items?.map(item => {
    if (item.selected) {
      return {
        ...item,
        components: item.components.map(component => {
          if (component.id === 'coupon_summary') {
            return {
              ...component,
              ...newCouponSummary,
            };
          }
          return component;
        }),
      };
    }
    return item;
  });
  const newBuyBoxOffers = {
    ...buy_box_offers,
    items: updatedItems,
  };
  return newBuyBoxOffers;
};

export default applyCouponSummaryToSelectedOffers;
