import React from 'react';
import { string, arrayOf } from 'prop-types';
import classnames from 'classnames';
import { generateKeyToComponent } from '../../utils/validators';

const namespace = 'ui-pdp-features';

const Features = ({ className, features }) => (
  <ul className={classnames(namespace, className)}>
    {features.map(feature => (
      <li key={generateKeyToComponent(feature)} className={`${namespace}__item`}>
        {feature}
      </li>
    ))}
  </ul>
);

Features.propTypes = {
  className: string,
  features: arrayOf(string),
};

Features.defaultProps = {
  className: null,
  features: null,
};

export default React.memo(Features);
