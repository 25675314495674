import React from 'react';
import { arrayOf, shape } from 'prop-types';

// Basics
import ComponentList from '../component-list';

import withMappedProps from '../../utils/withMappedProps';

// Highlighted Specs Components
import StyledLabel from '../styled-label';

import labelToProps from '../styled-label/label-to-props';
import HSHighlightedFeatures from './components/hs-highlighted-features';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'ui-vpp-highlighted-specs';

const availableComponentsTop = {};
availableComponentsTop.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponentsTop.highlighted_features = HSHighlightedFeatures;

const HighlightedSpecsFeatures = ({ components, viewport_track }) => {
  const initialState = { components };
  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  return (
    <section ref={viewRef} className={namespace}>
      <ComponentList availableComponents={availableComponentsTop} initialState={initialState} />
    </section>
  );
};

HighlightedSpecsFeatures.propTypes = {
  components: arrayOf(shape()).isRequired,
  viewport_track: shape({}),
};

HighlightedSpecsFeatures.defaultProps = {
  viewport_track: null,
};

export default React.memo(HighlightedSpecsFeatures);
