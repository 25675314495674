/* eslint-disable react/no-danger */
import React from 'react';

import { shape, string, arrayOf, bool } from 'prop-types';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'ui-pdp-description';

const HtmlDescription = ({ title, showTitle, show_track, html_content, imageLoading }) => {
  const refView = useTrackView(show_track, { threshold: 0.3 });

  React.useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.imageLazyLoading === 'function' && imageLoading) {
      window.imageLazyLoading();
    }
  }, [imageLoading]);

  return (
    <div ref={refView}>
      {showTitle && (
        <h2 data-testid="desc-title" className={`${namespace}__title`}>
          {title}
        </h2>
      )}
      <div data-testid="html-desc_content" className={`${namespace}-content`}>
        {html_content && html_content.html && <div dangerouslySetInnerHTML={{ __html: html_content.html }} />}
      </div>
    </div>
  );
};

HtmlDescription.propTypes = {
  imageLoading: bool,
  showTitle: bool,
  title: string.isRequired,
  show_track: shape({}),
  html_content: shape({
    html: string,
    styles: arrayOf(string),
  }).isRequired,
};

HtmlDescription.defaultProps = {
  imageLoading: false,
  showTitle: false,
  show_track: null,
};

export default React.memo(HtmlDescription);
