import React, { useContext } from 'react';
import { arrayOf, shape } from 'prop-types';
import classnames from 'classnames';
import MediaAction from '../../../media/media-action';
import HSAdditionalInformation from '../hs-additional-information';
import HSHighlightedFeaturesList from '../hs-highlighted-features-list';
import StaticPropsContext from '../../../context/static-props';

const namespace = 'ui-vpp-highlighted-specs__features';

const HSHighlightedFeatures = ({ features, action, additional_information: additionalInformation }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const isDesktop = deviceType === 'desktop';

  const onClickHandler = e => {
    e.preventDefault();
    const seeMoreButtonSpecs = document.querySelector(
      '.ui-vpp-highlighted-specs__striped-collapsed__action .ui-pdp-collapsable__action',
    );
    const spectScroll =
      document.querySelector('.ui-pdp-container__row--highlighted-specs-title') ??
      document.querySelector('.ui-pdp-container__row--technical-specifications');

    const specs = spectScroll;

    if (seeMoreButtonSpecs) {
      seeMoreButtonSpecs.click();
    }

    if (specs) {
      specs.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className={classnames(namespace, {
        'ui-pdp-with--separator': !isDesktop,
      })}
    >
      <HSHighlightedFeaturesList features={features} />
      {additionalInformation && <HSAdditionalInformation namespace={namespace} label={additionalInformation} />}
      {action && isDesktop && (
        <MediaAction
          target="#"
          className={`${namespace}-action`}
          title={action.label.text}
          label={action.label}
          track={action.track}
          data-testid="highlighted-features-action"
          onClick={onClickHandler}
        />
      )}
    </div>
  );
};

HSHighlightedFeatures.propTypes = {
  action: shape({}),
  features: arrayOf(shape({}).isRequired).isRequired,
  additional_information: shape({}),
};

HSHighlightedFeatures.defaultProps = {
  action: null,
  additional_information: undefined,
};

export default HSHighlightedFeatures;
