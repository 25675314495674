import React from 'react';
import ActionModal from '../components/action-modal';
import Iframe from '../components/iframe';

const getActionModal = (action, onIframeMessage, appendControlled = false) => {
  const targetUrl = appendControlled ? `${action.target}?controlled=true` : action.target;
  return (
    <ActionModal
      url={targetUrl}
      label={action.label}
      modalClassName="ui-pdp-iframe-modal"
      modalUrl="#payments"
      modalTitle={action.modal_title}
      autoHeight={false}
      closeModalLabel={action.close_modal_label}
    >
      <Iframe title={action.label.text} src={targetUrl} onMessage={onIframeMessage} scrolling="no" height={1000} />
    </ActionModal>
  );
};

export default getActionModal;
