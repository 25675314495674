import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import classNames from 'classnames';
import colornames from '../../lib/colornames';
import MediaAction from '../media/media-action';
import { PriceFontSize } from '../../lib/num-font-size-enum';
import { trackEvent } from '../../lib/tracking';
import { generateKeyToComponent } from '../../utils/validators';

const namespace = 'ui-pdp-products';

const OtherProducts = ({ title, className, products }) => (
  <div className={classNames(namespace, className)}>
    {title && <p className={classNames(`${namespace}__label`, colornames(title))}>{title.text}</p>}
    <ul className={`${namespace}__list`}>
      {products.map(product => (
        <li key={generateKeyToComponent(product)} className={`${namespace}__item`}>
          <MediaAction
            target={product.action.target}
            className={`${namespace}__link`}
            label={product.title}
            num_font_size={PriceFontSize.OTHER_PRODUCT}
            onClick={() => trackEvent(product.action.track)}
          />
        </li>
      ))}
    </ul>
  </div>
);

OtherProducts.propTypes = {
  className: string,
  products: arrayOf(
    shape({
      title: shape({
        text: string.isRequired,
        color: string,
        values: shape({
          price: shape({
            type: string,
            value: number,
          }),
        }),
      }),
      action: shape({
        target: string.isRequired,
        track: shape({}),
      }),
    }),
  ).isRequired,
  title: shape({
    text: string.isRequired,
    color: string,
  }).isRequired,
};

OtherProducts.defaultProps = {
  className: null,
};

export default OtherProducts;
