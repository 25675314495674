/**
 * Styles
 */
import '../../../pages/pdp/styles/pdp.desktop.scss';

/**
 * Polyfills
 */
import 'drift-zoom/dist/Drift.min';

/**
 * Module dependencies
 */
import bootstrap from '../../bootstrap';

import PdP from '../../../pages/pdp/components/views/main/main.desktop';

bootstrap(PdP);
