import { PROVIDER } from '../figure/constants';
import jsonToGalleryProps from '../gallery/json-to-props';

const jsonToProps = enhancedDescriptionProps => {
  const { figures, ...galleryProps } = jsonToGalleryProps({ ...enhancedDescriptionProps });
  return {
    ...galleryProps,
    viewPortTrack: enhancedDescriptionProps.viewport_track,
    maxShowPixels: enhancedDescriptionProps.max_show_pixels,
    pictures: figures.filter(({ provider }) => provider === PROVIDER.IMAGE),
  };
};

export default jsonToProps;
