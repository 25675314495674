import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import BannerDesktop from '../../../../../components/buybox/banner';
import * as actions from '../../../../../actions';
import { reloadPage } from './utils';

const FEATURE_NAME = 'generic_banner_installments';

const GENERIC_BANNER_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: genericBannerInstallments } }, ownProps) => ({
  ...genericBannerInstallments,
  ...ownProps,
  ...GENERIC_BANNER_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => {
    dispatch(actions.fetchProductOnMeliplusUpdate('close_modal'));
  },
  meliplusUpsellingOnSuscription: () => {
    reloadPage();
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(BannerDesktop);
export { mapDispatchToProps };
