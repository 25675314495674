import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import Variations from '../../../../components/variations/variations.desktop';
import * as actions from '../../../../actions';

const FEATURE_NAME = 'variations';
const FEATURE_AVAILABLE_QUANTITY_NAME = 'available_quantity';
const FEATURE_BULK_SALE_QUANTITY = 'bulk_sale_quantity';
const FEATURE_TRACK_NAME = 'track';

const mapStateToProps = ({
  components: {
    [FEATURE_NAME]: variations,
    [FEATURE_AVAILABLE_QUANTITY_NAME]: availableQuantity,
    [FEATURE_BULK_SALE_QUANTITY]: bulkSaleQuantity,
    [FEATURE_TRACK_NAME]: track,
  },
}) => {
  let selectedQuantity;

  if (bulkSaleQuantity?.state === 'VISIBLE' && Number.isInteger(bulkSaleQuantity.selected)) {
    selectedQuantity = bulkSaleQuantity.selected;
  } else {
    selectedQuantity = availableQuantity?.state !== 'HIDDEN' ? availableQuantity.picker?.selected : 1;
  }
  return {
    ...variations,
    ...track,
    selectedQuantity,
  };
};

const mapDispatchToProps = dispatch => ({
  onSelectVariation: (productId, params) => dispatch(actions.fetchComponents(productId, params)),
  setPickersCollapse: pickerId => dispatch(actions.setPickersCollapse(pickerId)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Variations);
