import React from 'react';
import { arrayOf, bool, string, shape, func, oneOfType, node, number } from 'prop-types';
import classnames from 'classnames';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import Media from '../media';
import { Remaining } from '../../prop-types';

import IconFactory from '../icons/factory-builder';
import { setTooltipProps } from '../tooltip/information-tooltip-props';
import adaptSubtitlesToRemainingLogic from '../../utils/adaptSubtitlesToRemainingLogic';
import withCardTooltip from '../card-tooltip/with-card-tooltip';

const namespace = 'ui-pdp-shipping';

const Shipping = ({
  action,
  className,
  icon,
  remaining,
  subtitles,
  title,
  key,
  isFetching,
  onShippingPromiseUpdate,
  showChevron,
  tooltip,
  num_font_size,
  showFetchingSpinner,
}) => {
  let adaptedSubtitles = null;

  if (subtitles) {
    adaptedSubtitles = subtitles.slice();
  }

  if (remaining && subtitles) {
    adaptSubtitlesToRemainingLogic(remaining, onShippingPromiseUpdate, subtitles, adaptedSubtitles);
  }
  return (
    <Media
      figure={icon && IconFactory(icon)}
      className={classnames(namespace, className, {
        [`${namespace}--remaining`]: remaining,
        [`${namespace}--first-buy`]: !!title?.values?.first_buy,
      })}
      subtitles={adaptedSubtitles}
      title={title}
      action={action}
      num_font_size={num_font_size}
      key={key}
      isFetching={isFetching}
      showChevron={showChevron}
      tooltip={tooltip}
    >
      {showFetchingSpinner && (
        <ProgressIndicatorCircular modifier="inline" size="medium" className={`${namespace}__spinner`} />
      )}
    </Media>
  );
};

Shipping.propTypes = {
  action: oneOfType([
    shape({
      label: string.isRequired,
      href: string.isRequired,
      onClick: func,
      target: string,
      title: string,
    }),
    node,
  ]),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  isFetching: bool,
  onShippingPromiseUpdate: func.isRequired,
  remaining: Remaining,
  key: string,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: oneOfType([
    string,
    shape({
      text: string.isRequired,
      values: shape({}),
      text_icon: shape({}),
    }),
  ]).isRequired,
  showChevron: bool,
  tooltip: shape({
    side: string,
    content: node,
  }),
  num_font_size: number,
  showFetchingSpinner: bool,
};

Shipping.defaultProps = {
  action: null,
  className: null,
  icon: null,
  key: null,
  isFetching: false,
  remaining: null,
  subtitles: null,
  showChevron: false,
  tooltip: null,
};

export default React.memo(setTooltipProps(withCardTooltip(Shipping, `${namespace}__card-tooltip`)));
