import React from 'react';
import StyledLabel from '../../../styled-label';
import { shape, string } from 'prop-types';

const HSAdditionalInformation = ({ namespace, label }) => {
  const { text, color, font_size, font_family } = label;
  return (
    <div className={`${namespace}__additional-information`}>
      <StyledLabel
        className={`${namespace}__additional-information__label`}
        text={text ?? label}
        color={color ?? 'BLACK'}
        font_size={font_size ?? 'XSMALL'}
        font_family={font_family ?? 'REGULAR'}
      />
    </div>
  );
};

HSAdditionalInformation.propTypes = {
  namespace: string.isRequired,
  label: shape({
    text: string.isRequired,
    color: string.isRequired,
    font_size: string.isRequired,
    font_family: string.isRequired,
  }).isRequired,
};

export default HSAdditionalInformation;
