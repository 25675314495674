import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions';

const OtherSellers = loadable(() => import('../../../../components/other-sellers'));

const FEATURE_NAME = 'bbw_alternatives';
const AVIALABLE_QUANTITY = 'available_quantity';
const BULK_SALE_QUANTITY = 'bulk_sale_quantity';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: other_sellers,
      [AVIALABLE_QUANTITY]: availableQuantity,
      [BULK_SALE_QUANTITY]: bulkSaleQuantity,
    },
    parentUrl,
  },
  ownProps,
) => {
  let selectedQuantity;
  if (bulkSaleQuantity?.state === 'VISIBLE' && Number.isInteger(bulkSaleQuantity.selected)) {
    selectedQuantity = bulkSaleQuantity.selected;
  } else {
    selectedQuantity =
      availableQuantity?.state !== 'HIDDEN'
        ? availableQuantity.picker?.selected ||
          (availableQuantity.quantity_selector && availableQuantity.quantity_selector.minimum_quantity)
        : 1;
  }

  return {
    ...ownProps,
    ...other_sellers,
    parentUrl,
    selectedQuantity,
  };
};
const mapDispatchToProps = dispatch => ({
  showAddToCartModal: ({ itemId, labelText }) => {
    dispatch(actions.showAddToCartModal({ itemId, labelText }));
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(OtherSellers);
