import React from 'react';
import { bool, func, shape, string, number, arrayOf } from 'prop-types';
import Script from 'nordic/script';
import classNames from 'classnames';
import ProgressIndicatorCircular from '@andes/progress-indicator-circular';
import HtmlDescription from './html-description';
import { ActionCollapsable } from '../action-collapsable';
import useFetchDeferred from '../../hooks/use-fetch-deferred';
import Grid from '../grid';
import { trackEvent } from '../../lib/tracking';

const namespace = 'ui-pdp-description';

const DEFAULT_OPTIONS = {
  rootMargin: '800px 0px',
  threshold: 0,
};

const ImageLoader = () => <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />;

const HtmlDescriptionDesktop = ({
  action,
  fetched,
  id,
  html_content,
  maxPages,
  max_height,
  state,
  title,
  viewport_track,
  onFetchDeferredComponent,
  ...rest
}) => {
  const isDeferred = state === 'DEFERRED';
  const isFetched = fetched;
  const [stylesCss, setStylesCss] = React.useState({ stylePath: null });
  const [stylesTimeout, setStylesTimeout] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [showSkeleton, setShowSkeleton] = React.useState(isDeferred && !isFetched);
  const [isTracked, setIsTracked] = React.useState(false);
  const refView = useFetchDeferred(onFetchDeferredComponent, id, { ...DEFAULT_OPTIONS });
  const actionButtonDimensions = 34 + 24; // button height + button top padding/marging
  const bottomPadding = 45;
  const minHeight = max_height + actionButtonDimensions + bottomPadding;

  React.useEffect(() => {
    if (html_content) {
      setStylesCss({
        stylePath: html_content.styles,
      });
    }

    if (stylesCss.stylePath) {
      setTimeout(() => {
        setStylesTimeout(true);
        setImageLoading(true);
      }, 1000);
    }

    if (!isDeferred && isFetched && stylesTimeout) {
      setShowSkeleton(false);

      if (!isTracked) {
        trackEvent(viewport_track);
        setIsTracked(true);
      }
    }
  }, [html_content, isDeferred, isFetched, isTracked, stylesCss.stylePath, stylesTimeout, viewport_track]);

  return (
    <>
      <ImageLoader />
      <Grid.Row>
        <Grid.Col size={3}>
          {stylesCss.stylePath &&
            stylesCss.stylePath.map(style => (
              <link key={style} data-testid="html-desc_styles" type="text/css" rel="stylesheet" href={style} />
            ))}
          <div ref={refView} className="ui-pdp-with--separator-auto pb-40 ui-pdp-with--separator--40-24">
            <div
              className={classNames(namespace, 'pl-24 pr-24 pb-45')}
              style={showSkeleton ? { height: `${minHeight}px` } : {}}
            >
              {showSkeleton && (
                <>
                  <h2 data-testid="desc-title" className={`${namespace}__title`}>
                    {title}
                  </h2>
                  <ProgressIndicatorCircular modifier="block" size="large" className={`${namespace}__spinner`} />
                </>
              )}
              {!isDeferred && isFetched && (
                <div style={showSkeleton ? { position: 'absolute', top: 0, zIndex: -1 } : {}}>
                  <ActionCollapsable
                    className="ui-pdp-description__action--center"
                    action={action}
                    collapseBy="pixels"
                    maxPages={maxPages}
                    maxCollapsed={max_height}
                    max={max_height}
                    scrollToTop={false}
                  >
                    <HtmlDescription
                      {...rest}
                      title={title}
                      html_content={html_content}
                      imageLoading={imageLoading}
                      showTitle
                    />
                  </ActionCollapsable>
                </div>
              )}
            </div>
          </div>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

HtmlDescriptionDesktop.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string,
  }),
  className: string,
  content: string,
  fetched: bool,
  id: string.isRequired,
  maxPages: number,
  max_height: number,
  state: string.isRequired,
  title: string,
  html_content: shape({
    html: string,
    styles: arrayOf(string),
  }),
  onFetchDeferredComponent: func,
  viewport_track: shape(),
};

HtmlDescriptionDesktop.defaultProps = {
  action: null,
  className: '',
  content: '',
  fetched: false,
  html_content: null,
  maxPages: null,
  max_height: 1200,
  onFetchDeferredComponent: null,
  title: '',
  viewport_track: null,
};

export default HtmlDescriptionDesktop;
