import React from 'react';
import { RecommendationsComparatorDesktop } from '@recommendations-fe/comparator';
import Grid from '../grid';
import RecommendationsIconFactory from './icon-factory';

const ComparatorDesktop = props => (
  <Grid.Row>
    <Grid.Col size={3}>
      <Grid.Row className="ui-pdp-container__comparator">
        <Grid.Col className="pl-24 pr-24">
          <RecommendationsComparatorDesktop IconFactory={RecommendationsIconFactory} {...props} />
        </Grid.Col>
      </Grid.Row>
    </Grid.Col>
  </Grid.Row>
);

export default ComparatorDesktop;
