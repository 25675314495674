import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import * as actions from '../../../../actions';

const FEATURE_NAME = 'html_description';

const mapStateToProps = ({ components: { [FEATURE_NAME]: description }, pathName }, ownProps) => ({
  ...description,
  maxPages: description.max_pages,
  ...ownProps,
  pathName,
});

const mapDispatchToProps = dispatch => ({
  onFetchDeferredComponent: component => dispatch(actions.fetchDeferredComponent(component)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = Component => enhance(Component);

export default component;
